<template>
  <VueFinalModal name="auth" v-bind="$attrs" classes="modal-container" content-class="modal-content" @closed="onClose">
    <div v-if="signin" id="signinModal" class="box-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('auth')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">{{ $t("modal.auth.login") }}</div>
      <div class="auth-modal">
        <form @submit.prevent="onLogin">
          <div class="group">
            <FontIcon icon="user" class="icon" size="24" />
            <input v-model="loginForm.login" name="name" required :placeholder="$t('modal.auth.placeholder.login_only')" type="text" />
          </div>
          <div class="group">
            <FontIcon icon="password" class="icon" size="24" />
            <input v-model="loginForm.password" name="password" required :placeholder="$t('modal.auth.placeholder.password')" type="password" />
          </div>
          <div class="flex">
            <button type="submit" class="auth">
              {{ $t("modal.auth.on_login") }}
              <FontIcon icon="arrow-long" class="icon" size="24" />
            </button>
            <div class="social">
              <span>{{ $t("modal.auth.or") }}</span>
              <a href="#" class="vk" @click="redirectVk">
                <img src="/icons/vk.svg" alt="" />
              </a>
            </div>
          </div>
        </form>
        <div class="description">{{ $t("modal.auth.notregistered") }}</div>
        <button class="warning" @click="signin = false">
          {{ $t("modal.auth.on_register") }}
        </button>
      </div>
    </div>
    <div v-else id="signupModal" class="box-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('auth')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">{{ $t("modal.auth.register") }}</div>
      <div class="auth-modal">
        <form @submit.prevent="onRegister">
          <div class="group">
            <FontIcon icon="user" class="icon" size="24" />
            <input v-model="registerForm.login" name="name" required :placeholder="$t('modal.auth.placeholder.login_only')" type="text" />
          </div>
          <div v-if="false" class="group">
            <FontIcon icon="email" class="icon" size="24" />
            <input v-model="registerForm.email" name="name" type="email" required :placeholder="$t('modal.auth.placeholder.email')" />
          </div>
          <div class="group">
            <FontIcon icon="password" class="icon" size="24" />
            <input v-model="registerForm.password" name="password" required :placeholder="$t('modal.auth.placeholder.password')" type="password" />
          </div>
          <div class="group">
            <FontIcon icon="password" class="icon" size="24" />
            <input v-model="registerForm.password_confirmation" name="password_confirmation" required :placeholder="$t('modal.auth.placeholder.password_confirmation')" type="password" />
          </div>
          <div class="description">
            {{ $t("modal.auth.agree") }}
            <NuxtLink :to="localePath('/terms')">{{ $t("modal.auth.terms") }}</NuxtLink>
            {{ $t("modal.auth.and") }}
            <NuxtLink :to="localePath('/policy')">{{ $t("modal.auth.policy") }}</NuxtLink
            >.
          </div>
          <div class="flex">
            <button type="submit" class="auth">
              {{ $t("modal.auth.register") }}
              <FontIcon icon="arrow-long" class="icon" size="24" />
            </button>
            <div class="social">
              <span>{{ $t("modal.auth.or") }}</span>
              <a href="#" class="vk" @click="redirectVk">
                <img src="/icons/vk.svg" alt="" />
              </a>
            </div>
          </div>
        </form>
        <div class="description">{{ $t("modal.auth.registered") }}</div>
        <button class="warning" @click="signin = true">
          {{ $t("modal.auth.do_login") }}
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
//import { useTournamentsStore } from "~~/store/tournaments";
import { storeToRefs } from "pinia";
import { useGameTournamentsStore } from "~~/store/gameTournaments";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { getFingerprint as mixGetFingerprint } from "~~/utils";
export default {
  components: { VueFinalModal },
  setup() {
    const localePath = useLocalePath();

    const signin = ref(true);
    const loading = ref(false);
    const app = useNuxtApp();

    const router = useRouter();
    const route = useRoute();
    const onClose = () => {
      router.replace({
        ...route,
        query: {},
      });
    };

    const refCode = useCookie("refCode");

    const loginForm = ref({
      login: "",
      password: "",
      fp: undefined,
    });
    const registerForm = ref({
      login: "",
      password: "",
      // email: "",
      password_confirmation: "",
      fp: undefined,
    });

    const { getData } = useVisitorData(
      { extendedResult: true },
      // Set to true to fetch data on mount
      { immediate: false }
    );

    const getFingerprint = async () => {
      return await mixGetFingerprint(getData);
    };

    const { $axios, $auth, $vfm } = app;

    const getTours = async () => {
      //const store = useTournamentsStore();
      const storeGame = useGameTournamentsStore();
      //const { statistics, award, top } = storeToRefs(store);
      const { award: gAwards } = storeToRefs(storeGame);
      try {
        /* $axios.$get("ref/tournaments/init").then(({ response: responsex }) => {
          if (responsex?.award?.bank) {
            top.value = responsex.top.slice(0, 10);
            statistics.value = responsex.statistics;
            award.value = responsex.award;
          }
        });*/
        // $axios
        //   .$get("bonus/tournaments/init")
        //   .then(({ response: responsex }) => {
        //     if (responsex?.award?.place) {
        //       gAwards.value = responsex.award;
        //     }
        //   });
        getNewYear();
      } catch (e) {
        //
      }
    };

    const redirectVk = async () => {
      const formData = { provider: "vk" };
      if (refCode.value) {
        formData.refCode = refCode.value.toString();
      }
      await $axios
        .$post("/auth/social", formData)
        .then(async ({ response }) => {
          if (response?.redirect) {
            setTimeout(() => {
              window.location = response?.redirect;
            }, 300);
            await sendGoal("social_vk_redirect");
            window.location = response?.redirect;
          }
        })
        .catch(({ response }) => {
          if (response?.data?.response?.redirect) {
            window.location = response?.data?.response?.redirect;
          }
        });
    };

    const onLogin = async () => {
      if (loading.value) return;
      try {
        const fp = await getFingerprint();
        if (fp) {
          loginForm.value.fp = fp;
        }
      } catch (e) {}
      loading.value = true;
      await $axios
        .$post("/auth/login", loginForm.value)
        .then(async ({ response }) => {
          if (response?.access_token) {
            const { access_token, user } = response;
            if (access_token) {
              sendGoal("auth_success");
              await $auth.strategy.token.set(access_token);
              await $auth.setUser(user);
              $vfm.hide("auth");
              getTours();
            }
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const onRegister = async () => {
      if (loading.value) return;

      if (refCode.value) {
        registerForm.value.refCode = refCode.value.toString();
      }
      const fp = await getFingerprint();
      if (fp) {
        registerForm.value.fp = fp;
      }
      loading.value = true;
      await $axios
        .$post("/auth/register", registerForm.value)
        .then(async ({ response }) => {
          if (response?.access_token) {
            const { access_token, user } = response;
            if (access_token) {
              sendGoal("register_success");
              await $auth.strategy.token.set(access_token);
              await $auth.setUser(user);
              $vfm.hide("auth");
              getTours();
            }
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return {
      loginForm,
      registerForm,
      signin,
      onLogin,
      onRegister,
      redirectVk,
      localePath,
      onClose,
    };
  },
};
</script>
